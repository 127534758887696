
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import Header from "@/components/Header.vue";
import SearchBar from "@/components/SearchBar.vue";
import { menuController } from "@ionic/vue";
import ApplyFilterMenu from "@/views/attendance/apply/filter/FilterMenu.vue";
import ApprovalCard from "@/components/ListCard/approvalCard.vue";
import { WorkflowService } from "@/services/attendance/workflow/WorkflowService";
import { LeaveTypeService } from "@/services/attendance/leavetype/LeaveTypeService";
export default defineComponent({
  name: "FilterApply",
  components: {
    Header,
    SearchBar,
    ApplyFilterMenu,
    ApprovalCard,
  },
  setup() {
    const api = new WorkflowService();
    const btnSizeRight = reactive({ data: ["38px"] });
    const btnIconRight = reactive({ data: ["iconshaixuan"] });
    const leaveTypeService = new LeaveTypeService();
    let checkIndex = ref(0);

    const chips = reactive({
      data: [
        { name: "全部", id: 0 },
        { name: "请假", id: 1 },
        { name: "出差", id: 2 },
        { name: "外出", id: 3 },
        { name: "异常打卡", id: 4 },
        { name: "驻场", id: 5 },
        { name: "销假", id: 6 },
        { name: "补假", id: 199 },
      ],
    });

    const checkChip = (i: number) => {
      checkIndex.value = i;
      resetPage();
    };

    const approvalList = reactive({ data: [] });

    const searchParams = reactive({
      page: 1,
      size: 10,
      workflowName: "",
      approvalStatus: "",
      startDate: "",
      endDate: "",
      vacationId: "",
    });
    let showNoDate = ref(false);
    const resetPage = () => {
      searchParams.page = 1;
      getListRequest((res: any) => {
        approvalList.data = res;
        showNoDate.value = res == null || res.length <= 0;
      });
    };
    const nextPage = (event: any) => {
      searchParams.page++;
      getListRequest((result: any) => {
        if (result) {
          approvalList.data = approvalList.data.concat(result);
        }
        event.target.complete();
      });
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    };
    const doRefresh = (event: any) => {
      searchParams.page = 1;
      // searchParams.workflowName = "";
      // searchParams.approvalStatus = "";
      // searchParams.startDate = "";
      // searchParams.endDate = "";
      getListRequest((res: any) => {
        approvalList.data = res;
        showNoDate.value = res == null || res.length <= 0;
      });
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    };
    const getListRequest = (callback: any) => {
      let params = {
        page: searchParams.page,
        size: searchParams.size,
        type: checkIndex.value == 0 ? "" : checkIndex.value,
        workflowName: searchParams.workflowName,
        approvalStatus: searchParams.approvalStatus,
        startDate: searchParams.startDate,
        endDate: searchParams.endDate,
        vacationId: searchParams.vacationId,
      };
      api
        .getDoneList(params)
        .then((res: any) => {
          callback(res);
        })
        .catch(() => {});
    };

    let vacationTypeList = reactive({ data: [] });
    let vacationId = ref("");
    const getVacationType = () => {
      leaveTypeService
        .getVacationType()
        .then((res: any) => {
          if (res) {
            vacationTypeList.data = res;
          }
        })
        .catch(() => {});
    };
    const changeVacationType = (id: any) => {
      vacationId.value = id;
      searchParams.vacationId = vacationId.value;
      resetPage();
    };
    onMounted(() => {
      getVacationType();
      resetPage();
    });

    const router = useRouter();

    const todoInfo = (item: any) => {
      const path = ref();
      let handleType = 1;
      if (item.workflowType == 1) {
        path.value = "/LeaveInfo";
      } else if (item.workflowType == 2) {
        path.value = "/TravelInfo";
      } else if (item.workflowType == 3) {
        path.value = "/OndutyInfo";
      } else if (item.workflowType === 4) {
        path.value = "/AbnormalInfo";
      } else if (item.workflowType == 5) {
        path.value = "/StationInfo";
      } else if (item.workflowType === 6) {
        path.value = "/CancelLeaveInfo";
      }
      router.push({
        path: path.value,
        query: {
          id: item.businessId,
          procId: item.processInstanceId,
          taskId: item.taskId,
          handleType: handleType,
          type: 0,
          typeLeave: checkIndex.value,
        },
      });
    };

    const searchHandler = (e: any) => {
      searchParams.workflowName = e.searchKey;
      resetPage();
    };

    const openMenuModal = (r: any) => {
      if (r == "0_icon") {
        menuController.enable(true, "ApplyFilterMenu");
        menuController.open("ApplyFilterMenu");
      }
    };

    const filterHandler = (data: any) => {
      menuController.close();
      checkIndex.value = 0;
      searchParams.workflowName = data.name;
      searchParams.approvalStatus = data.status;
      searchParams.startDate = data.dateStart;
      searchParams.endDate = data.dateEnd;
      resetPage();

      // searchParams.startDateEnd=data.,
      // searchParams.endDateStart=data.,
      // searchParams.endDateEnd=data.,
      // initDate(r);
    };

    return {
      btnSizeRight,
      btnIconRight,
      checkIndex,
      checkChip,
      chips,
      approvalList,
      todoInfo,
      searchHandler,
      openMenuModal,
      filterHandler,
      nextPage,
      doRefresh,
      showNoDate,
      vacationTypeList,
      vacationId,
      changeVacationType,
    };
  },
});
