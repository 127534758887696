
import { defineComponent } from "vue";
import Utils from "@/utils/Utils";
export default defineComponent({
  name: "approvalCard",
  props: {
    approvalObj: Object,
    isDone: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    subStringUserName(userName: string) {
      if (!userName) {
        return "";
      }
      return Utils.subStringUserName(userName, 2);
    },
  },
});
